@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Black.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-BlackItalic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-BoldCondensed.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-BoldCondensedItalic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-BoldItalic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-Condensed.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-CondensedItalic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-Italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-Light.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-LightItalic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-Medium.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-MediumItalic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-Regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-Thin.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Roboto-ThinItalic.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

/* use the font */
body {
  font-family: "Roboto-Black", sans-serif;
  font-size: 48px;
  color: white;
  /* text-transform: ; */
}